import Dropzone from 'dropzone';
import DropzoneElement from './dropzone-handler';
import Quill from 'quill';

export default {
	searchClient: null,
	posts: null,
    init() {

        Dropzone.autoDiscover = false;

        if(document.querySelector('.dropzone') !== null) {
			document.querySelectorAll('.dropzone').forEach(el => {
				el.dz = new DropzoneElement(el);
			});
		}

        if(document.querySelector('.wysiwyg') !== null) {
			document.querySelectorAll('.wysiwyg').forEach(el => {
				el.wysiwyg = new Quill(el, {
					modules: {
						toolbar: [
							[{ header: [1, 2, 3, false] }],
							['bold', 'italic', 'underline'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }],
						]
					},
					placeholder: el.nextElementSibling.getAttribute('placeholder'),
					theme: 'snow'  // or 'bubble'
				});
				el.wysiwyg.on('text-change', ev => {
					el.nextElementSibling.value = el.children[0].innerHTML;
				});
			});
		}

		if(document.querySelector('select[name="shared_with"]') !== null) {
			document.querySelectorAll('select[name="shared_with"]').forEach(el => {
				el.addEventListener('change', ev => {
					let $actions = el.closest('.publish-control').querySelector('.actions');

					$actions.querySelectorAll('[class^="actions__"]').forEach(el => { el.style.display = 'none'; });
					if($actions.querySelector('.actions__' + el.value) !== null){
						$actions.querySelector('.actions__' + el.value).style.display = 'block';
					}
				});
			});
		}

        // Button to add one additional field

		this.searchClient = algoliasearch("P16WZ121L2", "4d8cff2428a773a0de592e7c39282ccd");
		this.posts = this.searchClient.initIndex('wp_posts');

		if(document.querySelector('.reference-article') !== null){
			document.querySelectorAll('.reference-article').forEach(this.attachAutocomplete.bind(this))
		}

        if(document.querySelector('.repeating-field .btn-add') !== null){
			document.querySelectorAll('.repeating-field .btn-add').forEach(el => {
				el.addEventListener('click',  this.attachRepeatingHandler.bind(this));
            })
        }

        if(document.querySelector('.repeating-field input.form__control') !== null){
			document.querySelectorAll('.repeating-field input.form__control').forEach(el => {
				el.addEventListener('keypress', ev => {
					if(ev.which === 13){
						ev.preventDefault();
						this.attachRepeatingHandler(ev);
					}
				});
            })
        }

        if(document.querySelector('.repeating-field .item__delete') !== null){
			document.querySelectorAll('.repeating-field .item__delete').forEach(el => {
				el.addEventListener('click', this.removeChild);
            })
        }

        if(document.getElementById('add_lesson_button') !== null){
			document.getElementById('add_lesson_button').addEventListener('click', ev => {
				ev.preventDefault();

				let template = document.getElementById('template-lesson'),
					html 	 = template.innerText,
					lessonNo = document.querySelectorAll('#lesson-form-items .lesson-plan').length;

				html = html.replace(/{{lesson_index}}/g, lessonNo)
							.replace(/{{lesson_number}}/g, lessonNo + 1);

				html = new DOMParser().parseFromString(html, "text/html");

				if(html.querySelector('.repeating-field .btn-add') !== null){
					html.querySelectorAll('.repeating-field .btn-add').forEach(el => {
						el.addEventListener('click',  this.attachRepeatingHandler.bind(this));
					})
				}

				if(html.querySelector('.dropzone') !== null) {
					html.querySelectorAll('.dropzone').forEach(el => {
						el.dz = new DropzoneElement(el);
					});
				}

				if(html.querySelector('.wysiwyg') !== null) {
					html.querySelectorAll('.wysiwyg').forEach(el => {
						el.wysiwyg = new Quill(el, {
							modules: {
								toolbar: [
									[{ header: [1, 2, 3, false] }],
									['bold', 'italic', 'underline'],
									[{ 'list': 'ordered'}, { 'list': 'bullet' }],
								]
							},
							placeholder: 'Add an introduction section',
							theme: 'snow'  // or 'bubble'
						});
						el.wysiwyg.on('text-change', ev => {
							el.nextElementSibling.value = el.children[0].innerHTML;
						});
					});
				}

				if(document.getElementById('lesson-form-items').querySelector('.no-lessons') !== null){
					document.getElementById('lesson-form-items').innerHTML = '';
				}

				document.getElementById('lesson-form-items').appendChild(html.body.firstChild);
			});
		}
    },

	removeChild(ev){
		ev.preventDefault();

		let item = ev.currentTarget.closest('.item');
		item.parentNode.removeChild(item);
	},

	attachAutocomplete(el){
		autocomplete(el, {debug: true}, [
			{
				source: autocomplete.sources.hits(this.posts, { hitsPerPage: 6, distinct: true }),
				displayKey: 'title',
				name: "postsDropdown",
				keyboardShortcuts: ['s', '/'],
				templates: {
					suggestion(suggestion) {
						let tempUrl = suggestion.url.replace('staging.teaching.classcreative.co.uk', window.location.hostname);


						return `<span href='${tempUrl}'>${suggestion._highlightResult.title.value}</span>`;
					}
				}
			}
		]).on('autocomplete:selected', function(event, suggestion, dataset, context) {
			let id = suggestion.distinct_key.replace('post#', '');

			event.target.dataset.id = id;
			event.target.closest('.search').querySelector('input[name="article_ids[]"]').value = id;
		});
	},

	attachRepeatingHandler(event){
		event.preventDefault();

		if(event.currentTarget.closest('.repeating-field').querySelector('.item-template') !== null){
			let template = event.currentTarget.closest('.repeating-field').querySelector('.item-template'),
				html 	 = template.content.cloneNode(true),
				idx 	 = event.currentTarget.closest('.repeating-field').querySelectorAll('.item').length;

			if(html.querySelector('input[name*="idx"]') !== null){
				html.querySelectorAll('input[name*="idx"]').forEach(el => {
					el.name = el.name.replace(/idx/g, idx);
				})
			}

			if(html.querySelector('input.form__control')){
				html.querySelectorAll('input.form__control').forEach(el => {
					el.addEventListener('keypress', ev => {
						if(ev.which === 13){
							ev.preventDefault();
							this.attachRepeatingHandler(ev);
						}
					});
				});
			}
			
			if(html.querySelector('.item__delete') !== null){
				html.querySelectorAll('.item__delete').forEach(el => {
					el.addEventListener('click', this.removeChild);
				});
			}

			if(template.classList.contains('search')) {
				this.attachAutocomplete(html.querySelector('input[name="article_titles[]"]'));
			}

			event.currentTarget.closest('.repeating-field').querySelector('.inputs').appendChild(html);

			if(event.currentTarget.closest('.repeating-field').querySelector('.inputs input.form__control') !== null){
				let allInputs = event.currentTarget.closest('.repeating-field').querySelectorAll('.inputs input.form__control');
				allInputs[allInputs.length - 1].focus();
			}

		}
	}
}
