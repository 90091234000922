export default class ReadMore{
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.container = element;
		this.open = false;
		this.button = element.nextElementSibling;

		this.init();
	}

	init(){
		this.container.style.maxHeight = 'none';

		if(this.container.offsetHeight > 500){
			this.button.style.display = 'block';
			this.button.addEventListener('click', this.toggle.bind(this));
		}

		this.container.style.maxHeight = '50rem';
	}

	toggle(ev){
		ev.preventDefault();

		if(this.open){
			this.container.style.maxHeight = '50rem';
			this.open = false;
			this.button.innerText = 'Read more';
			this.button.classList.remove('less');
		} else {
			this.container.style.maxHeight = 'none';
			this.open = true;
			this.button.innerText = 'Read less';
			this.button.classList.add('less');
		}
	}
}
