import Swiper from 'swiper/dist/js/swiper.js';

export default {
	init: function(){
		if(document.querySelector('#home-header-slider')){
			this.headerSlider(document.querySelector('#home-header-slider'));
		}
	},
	headerSlider: function(el){
		el.swiper = new Swiper(el, {
			speed: 400,
			allowTouchMove: false,
			effect : 'fade',
			fadeEffect: {
				crossFade: true
			},
		});

		if(document.querySelector('#home-header-pagination')){
			let pagi  	  = document.querySelector('#home-header-pagination ul'),
				indicator = pagi.querySelector('.indicator'),
				count 	  = pagi.querySelectorAll('li').length,
				width 	  = (100 / count).toFixed(2);

			pagi.querySelectorAll('li').forEach(el => {
				el.style.width = width + '%';
			});

			pagi.closest('#home-header-pagination').style.opacity = '1';
			indicator.style.width = 'calc(' + width + '% - 20px)';
			indicator.style.left = '10px';

			pagi.querySelectorAll('a').forEach(a => {
				a.addEventListener('click', ev => {
					ev.preventDefault();

					let index = [...pagi.querySelectorAll('li')].indexOf(a.parentNode);

					el.swiper.slideTo(index);
				});
			});

			el.swiper.on('slideChange', ev => {
				let a = pagi.querySelectorAll('li')[el.swiper.activeIndex].querySelector('a');
				indicator.style.left = a.offsetLeft + 'px';
			});
		}
	}
};
