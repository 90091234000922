/* globals jQuery */

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import Home from './modules/home';
import Register from './modules/register';
import Algolia from './modules/algolia';
import CalloutSlider from './modules/callout-slider';
import Accordion from './modules/accordion';
import Likes from './modules/likes';
import Topics from './modules/topics';
import TeachingResource from './modules/teaching-resource';
import CreateResource from './modules/create-resource';
import MicroModal from 'micromodal';
import PublishControl from './modules/publish-control';
import FilterSelect from './modules/filter-select';
import ReadMore from './modules/read-more';
import Dropzone from "dropzone";
import autoComplete from "@tarekraafat/autocomplete.js";

window.MicroModal = MicroModal;

window.teachingtimes = {
	init: function() {
		console.log("teachingtimesinit");
		if(typeof NodeList.prototype.forEach !== 'function'){
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		Dropzone.autoDiscover = false;

		Home.init();
		TeachingResource.init();
		CalloutSlider.init();
		Algolia.init();
		// init PublishControl before CreateResource
		CreateResource.init();
		PublishControl.init();
		FilterSelect.init();
		Likes.init();
		Topics.init();
		
		MicroModal.init({
			onShow: modal => console.info(`${modal.id} is shown`), // [1]
			onClose: modal => console.info(`${modal.id} is closed`), // [2]
			disableScroll: true, // [5]
			disableFocus: false, // [6]
			awaitCloseAnimation: false, // [7]
			debugMode: false // [8]
		});

		if(document.querySelector('#institution-select') !== null){
			new autoComplete({
				data: {                              // Data src [Array, Function, Async] | (REQUIRED)
				  src: async () => {
				  	if(typeof window.wordpress_ajax === "undefined"){
				  		return [];
					}

					// User search query
					const query = document.querySelector("#institution-select").value;
					// Fetch External Data Source
					const source = await fetch(`${window.wordpress_ajax.ajax_url}?action=search_teams&q=${query}`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json'
						},
						credentials: 'same-origin'
					});
					// Format data into JSON
					const data = await source.json();

					// Return Fetched data
					return data;
				  },
					key: ["post_title"],
				  	cache: false
				},
				sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
					if (a.match < b.match) return -1;
					if (a.match > b.match) return 1;
					return 0;
				},
				placeHolder: "Search Institutions",  // Place Holder text                 | (Optional)
				selector: "#institution-select",     // Input field selector              | (Optional)
				observer: true,                      // Input field observer 			  | (Optional)
				threshold: 3,                        // Min. Chars length to start Engine | (Optional)
				debounce: 400,                       // Post duration for engine to start | (Optional)
				searchEngine: "strict",              // Search Engine type/mode           | (Optional)
				resultsList: {                       // Rendered results list object      | (Optional)
					container: source => {
						console.log('source', source);
						source.setAttribute("id", "institution_list");
					},
					destination: "#institution-select",
					position: "afterend",
					element: "ul"
				},
				maxResults: 5,                         // Max. number of rendered results | (Optional)
				highlight: true,                       // Highlight matching results      | (Optional)
				resultItem: {                          // Rendered result item            | (Optional)
					content: (data, source) => {
						source.innerHTML = `
							<span data-href="${data.value.url}" data-id="${data.value.idp_name}">${data.value.post_title}</span>
						`;
					},
					element: "li"
				},
				noResults: (dataFeedback, generateList) => {
					// Generate autoComplete List
					generateList(autoCompleteJS, dataFeedback, dataFeedback.results);
					// No Results List Item
					const result = document.createElement("li");
					result.setAttribute("class", "no_result");
					result.setAttribute("tabindex", "1");
					result.innerHTML = `<span style="display: flex; align-items: center; font-weight: 100; color: rgba(0,0,0,.2);">Found No Results</span>`;
					document.querySelector(`#institution_list`).appendChild(result);
				},
				onSelection: (feedback) => {
					if(typeof feedback.selection !== "undefined"){
						if (typeof window.localStorage !== 'undefined') {
							window.localStorage.setItem("last-institution", feedback.selection.value.idp_name || null);
							window.localStorage.setItem("last-institution-url", feedback.selection.value.url || null);
						}

						if(typeof feedback.selection.value.url !== "undefined"){
							window.location.assign(feedback.selection.value.url);
						}
					}
				}
			});

            if (typeof window.localStorage !== 'undefined') {
				document.querySelector('#institution-select').addEventListener('init', (ev) => {
					if(window.localStorage.getItem("last-institution") !== null && window.localStorage.getItem("last-institution-url") !== null){
						document.querySelector('#last-institution').innerHTML = `
                            <label>Last logged in via:</label>
							<a href="${window.localStorage.getItem("last-institution-url")}">
								${window.localStorage.getItem("last-institution")}
							</a>
						`;
					}
				});
			}
		}

		if(document.querySelector('#website-register-form') !== null){
			Register.init();
		}

		if(document.body.classList.contains('single-knowledge-bank') && document.querySelector('.article__content') !== null){
			document.querySelectorAll('.article__content').forEach(el => {
				el.readMore = new ReadMore(el);
			});
		}

		if(document.querySelector('.accordion') !== null){
			document.querySelectorAll('.accordion').forEach(ele => {
				ele.accordion = new Accordion(ele);
			});
		}

		if(document.querySelector('.nav-trigger') !== null){
			document.querySelectorAll('.nav-trigger').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					ev.currentTarget.closest('#page-nav').classList.remove('search-open');
					ev.currentTarget.closest('#page-nav').classList.toggle('nav-open');
				});
			});
		}

		if(document.querySelector('.search-trigger') !== null){
			document.querySelectorAll('.search-trigger').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					ev.currentTarget.closest('#page-nav').classList.remove('nav-open');
					ev.currentTarget.closest('#page-nav').classList.toggle('search-open');
				});
			});
		}

		if(document.querySelector('select.page-navigation') !== null){
			document.querySelectorAll('select.page-navigation').forEach(el => {
				el.addEventListener('change', ev => {
					ev.preventDefault();

					window.location = ev.currentTarget.value;
				});
			});
		}

		if(document.querySelector('#knowledge-bank-curation') !== null ){
			document.querySelectorAll('#knowledge-bank-curation select').forEach(el => {
				el.addEventListener('change', ev => {
					var form = document.querySelector('#knowledge-bank-curation');
					var title = document.querySelector('#knowledge-bank-curation input[type="text"]');

					if( ev.currentTarget.value === 'new' ){

						form.action = "/my-account/create-knowledge-bank/?post-to-add=" + form.getAttribute('data-post-id') + "&title=" + title.value;
						title.style.display = 'block';

					}else{
						form.action = "/my-account/edit-knowledge-bank/" + ev.currentTarget.value + "?post-to-add=" + form.getAttribute('data-post-id');
						title.style.display = 'none';
					}
				});
			});
		}

		if(document.querySelector('#knowledge-bank-curation') !== null ){
			document.querySelectorAll('#knowledge-bank-curation input[type="text"]').forEach(el => {
				el.addEventListener('change', ev => {
					var form = document.querySelector('#knowledge-bank-curation');
					var select = document.querySelector('#knowledge-bank-curation select');

					if( select.value === 'new' ){
						form.action = "/my-account/create-knowledge-bank/?post-to-add=" + form.getAttribute('data-post-id') + "&title=" + ev.currentTarget.value;
					}else{
						form.action = "/my-account/edit-knowledge-bank/" + select.value + "?post-to-add=" + form.getAttribute('data-post-id');
					}
				})
			});
		}

		if(document.querySelector('#knowledge-bank-curation .btn') !== null){
			document.querySelectorAll('#knowledge-bank-curation .btn').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					var form = document.querySelector('#knowledge-bank-curation');

					window.location = form.action;
				});
			});
		}

		if(document.querySelector('.fake-tab') !== null ){
			document.querySelectorAll('.fake-tab').forEach(el => {
				el.addEventListener('change', ev => {
					if(document.querySelector('#type-nav input[value=' + ev.currentTarget.value + ']') !== null){
						document.querySelector('#type-nav input[value=' + ev.currentTarget.value + ']').click();
					}
				})
			});
		}

		if(document.querySelector('#account-nav') !== null){
			document.querySelector('.menu-overlay').style.height = document.body.offsetHeight + 'px';

			document.querySelectorAll('.avatar a').forEach(el => {
				el.addEventListener('click', ev =>{
					ev.preventDefault();

					let offset = ev.currentTarget.getBoundingClientRect();

					document.querySelector('.menu-overlay').style.height = document.body.offsetHeight + 'px';
					document.querySelector('#account-nav').style.top =  ((offset.top + ev.currentTarget.offsetHeight) + 11)  + 'px';
					document.querySelector('#account-nav').style.right =  (((document.body.offsetWidth - offset.left) - (offset.width / 2)) - 25)  + 'px';

					document.body.classList.toggle('account-nav-open');
				});
			});

			document.querySelectorAll('.menu-overlay').forEach(el => {
				el.addEventListener('click', ev =>{
					ev.preventDefault();

					document.body.classList.remove('account-nav-open');
				});
			});
		}

		if(document.querySelector('.video-link')){
			document.querySelectorAll('.video-link').forEach(el => {
				el.addEventListener('click', function(ev){
					ev.preventDefault();
					
					document.querySelector('#video-modal iframe').setAttribute('src', ev.currentTarget.getAttribute('href'));
					MicroModal.show('video-modal', {
						onClose: modal => {
							if(modal.querySelector('iframe') !== null){
								modal.querySelectorAll('iframe').forEach(el => {
									el.src = '';
								});
							}
						}, // [2]
					});
				});
			});
		}

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});
	},

	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

window.teachingtimes.init();

// SVG Sprite Loader
// eslint-disable-next-line no-unused-vars
const __sprite__ = {
	path: '../../resources/images/icons/**/*.svg', //Source SVGs path relative to the compiled js (dist/js)
	name: 'images/icons.svg', //Compiled file path relative to dist/
};

svgxhr({
	filename: './wp-content/themes/class/dist/images/icons.svg', //URL to fetch
});
