export default {
	init(){
		console.log(window.wpApiSettings);
		if(document.querySelector('.btn--follow-topic') !== null){
			document.querySelectorAll('.btn--follow-topic').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					if(typeof ev.currentTarget.dataset.followed === 'undefined' || typeof ev.currentTarget.dataset.catId === 'undefined'){
						return false;
					}

					let catId  = ev.currentTarget.dataset.catId,
						status = JSON.parse(ev.currentTarget.dataset.followed),
						url    = `${window.wpApiSettings.root}topics/v1/${catId}`,
						type   = (status) ? 'DELETE' : 'POST';

					this._makeRequest(url, type)
						.then(data => {
							console.log(ev);
							ev.target.dataset.followed = !status;
							if(status){
								ev.target.classList.remove('btn--outline');
								ev.target.innerText = 'Follow this Topic';
							} else {
								ev.target.classList.add('btn--outline');
								ev.target.innerText = 'Unfollow this Topic';
							}
						});
				});
			});
		}
	},

	_makeRequest(url, type, data) {
		data = data || {};
		let formData;

		if(!(data instanceof FormData)) {
			formData = new FormData();
			Object.keys(data).forEach(key => formData.append(key, data[key]));
		} else {
			formData = data;
		}

		return fetch(url, {
			method: type || 'GET',
			redirect: 'error',
			mode: 'cors',
			referer: 'no-referrer',
			headers: {
				'Accept': 'application/json',
				'X-Requested-With' : 'XMLHttpRequest',
				'X-WP-NONCE': window.wpApiSettings.nonce,
			},
			body: type !== 'GET' ? formData : null,
		}).then(response => {
			return response.json().then(json => {
				if(response.ok) {
					return json;
				} else {
					let errors = {};

					if(json.exception || json.exception) {
						errors.server = [json.message || json.exception];
					}

					response.errors = json.errors || errors;

					console.error(response);

					return Promise.reject(response);
				}
			});
		})
	}
}