export default {
	init() {
		wpApiSettings = window.wpApiSettings;

		const likeButton = document.querySelector('.actions .like-button');
		const bookmarkButton = document.querySelector('.actions .bookmark-button');
		const unbookmarkButton = document.querySelectorAll('.unbookmark-button');

		// Like Button onClick event listener
		if( likeButton ){
			likeButton.addEventListener('click', event => {
				event.preventDefault();

				var postID = $('#page-content').attr('data-post-id');

				if( !event.currentTarget.classList.contains('active') ){
					this.addLike(postID, 'likes', () => {
						likeButton.classList.add('active');
					});
				} else {
					this.removeLike(postID, 'likes', () => {
						likeButton.classList.remove('active');
					});
				}
			});
		}

		// Bookmark Button onClick event listener
		if( bookmarkButton ){
			//this.getLikesForPost();
			bookmarkButton.addEventListener('click', event => {
				event.preventDefault();

				var postID = $('#page-content').attr('data-post-id');

				if( !event.currentTarget.classList.contains('active') ){
					this.addLike(postID, 'bookmarks', () => {
						bookmarkButton.classList.add('active');
					});
				} else {
					this.removeLike(postID, 'bookmarks', () => {
						bookmarkButton.classList.remove('active');
					});
				}
				//console.log('bookmark Click count: ' + event.detail);
			});
		}

		// Remove Bookmark Button onClick event listener
		if ( unbookmarkButton ) {
			// for each button on page, create a new event listener
			for(var i = 0; i < unbookmarkButton.length; i++){
				unbookmarkButton[i].addEventListener('click', event => {
					event.preventDefault();
					// get the post id
					var postID = event.target.getAttribute('data-post-id');
					// remove user bookmark from database
					this.removeLike(postID, 'bookmarks', () => {
						// reload page on callback
						location.reload();
					});
					//console.log("Bookmarks 'X' button Clicked" + event.detail);
					//console.log('postID: ' + postID);
				});
			}
		}
	},

	addLike(postID, type, callback){
		$.ajax( {
			url: wpApiSettings.root + 'likes/v1/' + type,
			method: 'POST',
			data: {
				post_id: postID
			},
			beforeSend: function ( xhr ) {
				xhr.setRequestHeader( 'X-WP-Nonce', wpApiSettings.nonce );
			},
		}).done( function ( response ) {
			console.log( response );
			callback();
		});
	},

	removeLike(postID, type, callback){
		$.ajax( {
			url: wpApiSettings.root + 'likes/v1/' + type,
			method: 'DELETE',
			data: {
				post_id: postID
			},
			beforeSend: function ( xhr ) {
				xhr.setRequestHeader( 'X-WP-Nonce', wpApiSettings.nonce );
			},
		}).done( function ( response ) {
			console.log( response );
			callback();
		});
	},

	getLikesForPost(postID, type){
		var postID = $('#page-content').attr('data-post-id');

		$.ajax( {
			url: wpApiSettings.root + 'likes/v1/likes/' + postID,
			method: 'GET',
			beforeSend: function ( xhr ) {
				xhr.setRequestHeader( 'X-WP-Nonce', wpApiSettings.nonce );
			},
		} ).done( function ( response ) {
			console.log( response );
		} );
	}

}
