import Swiper from 'swiper';

export default{
	init: function(){
		if(document.querySelector('.lesson-slides')){
			document.querySelectorAll('.lesson-slides').forEach(this.initSlider.bind(this));
		}
	},
	initSlider: function(el){
		let nav 	 = el.parentElement.querySelector('.lesson-nav'),
			dropDown = el.parentElement.querySelector('.lesson-dropdown select');

		el.swiper = new Swiper(el, {
			speed: 400,
			allowTouchMove: false,
			autoHeight: true,
			on: {
				init: function () {
					nav.querySelectorAll('li').forEach(el => el.classList.remove('is-active'));
					nav.querySelectorAll('li')[0].classList.add('is-active');
				},
				slideChange: function(){
					nav.querySelectorAll('li').forEach(el => el.classList.remove('is-active'));
					nav.querySelectorAll('li')[this.activeIndex].classList.add('is-active');

					if(dropDown !== null){
						dropDown.selectedIndex = this.activeIndex;
					}
				}
			},
		});

		if(dropDown !== null){
			dropDown.addEventListener('change', ev => {
				el.swiper.slideTo(parseInt(ev.currentTarget.value));
			});
		}

		if(nav.querySelector('li a') !== null){
			nav.querySelectorAll('li a').forEach(a => {
				a.addEventListener('click', ev => {
					ev.preventDefault();

					let index = [...nav.querySelectorAll('li')].indexOf(a.parentNode);

					el.swiper.slideTo(index);
				});
			});
		}
	}
}
