export default {
  init() {
    const searchClient = algoliasearch("P16WZ121L2", "4d8cff2428a773a0de592e7c39282ccd");

    const search = instantsearch({
      indexName: "wp_posts",
      query: 's',
      searchClient,
      searchParameters: {
        hitsPerPage: 10,
        distinct: true
      },
      routing: {
        stateMapping: {
          stateToRoute(uiState) {
            return {
              s: uiState.query,
              page: uiState.page
            };
          },

          routeToState(routeState) {
            return {
              query: routeState.s,
              page: routeState.page
            };
          }
        }
      },
      searchFunction(helper) {
        if (helper.state.query) {
          helper.search();
        }
      },
    });

    const posts = searchClient.initIndex('wp_posts');
    //const teams = client.initIndex('teams');

    autocomplete('.form__control.search', {debug: true}, [
        {
          source: autocomplete.sources.hits(posts, { hitsPerPage: 6, distinct: true }),
          displayKey: 'title',
          name: "postsDropdown",
          keyboardShortcuts: ['s', '/'],
          templates: {
            header: '<div class="aa-suggestions-category">Articles</div>',
            suggestion(suggestion) {
              console.log(suggestion._highlightResult);
              return `<a href='${suggestion.url}'>${suggestion._highlightResult.title.value}</a>`;
            }
          }
        }
    ]).on('autocomplete:selected', function(event, suggestion, dataset, context) {
      // Do nothing on click, as the browser will already do it
      if (context.selectionMethod === 'click') {
        return;
      }
      // Change the page, for example, on other events
      window.location.assign(suggestion.url);
    }).on('keyup', function(event) { //additional event listener 
        if (event.keyCode == 13) { // listen for when enter is pressed
            var protocol = location.protocol;
            var slashes = protocol.concat("//");
            var host = slashes.concat(window.location.hostname);

            window.location.href = host + '?s=' + $(event.target)[0].value; // go to your search results page
        }
    });

    if( $(".searchpage #searchbox").length != 0 ){
      search.addWidget(
        instantsearch.widgets.searchBox({
          container: '.searchpage #searchbox',
          cssClasses: 'form_control search',
          limit: 5,
          showMore: true,
        })
      );
    }
    
    if( $(".searchpage #category-list").length != 0 ){
      search.addWidget(
        instantsearch.widgets.refinementList({
          container: '.searchpage #category-list',
          attribute: 'categories',
        })
      );
    }

    if( $(".searchpage #key-stage-list").length != 0 ){
      search.addWidget(
        instantsearch.widgets.refinementList({
          container: '.searchpage #key-stage-list',
          attribute: 'key_stages',
        })
      );
    }

    if( $(".searchpage #author-list").length != 0 ){
      search.addWidget(
        instantsearch.widgets.refinementList({
          container: '.searchpage #author-list',
          attribute: 'author',
        })
      );
    }

    if( $(".searchpage #pagination").length != 0 ){
      search.addWidget(
        instantsearch.widgets.pagination({
          container: '.searchpage #pagination'
        })
      );
    }

    if( $(".searchpage #stats").length != 0 ){
      search.addWidget(
        instantsearch.widgets.stats({
          container: '.searchpage #stats'
        })
      );
    }

    if( $(".searchpage #sort").length != 0 ){
      search.addWidget(
        instantsearch.widgets.sortBy({
          container: '.searchpage #sort',
          items: [
            { value: 'wp_posts', label: 'Most relevant' },
            { value: 'wp_posts_post_date_desc', label: 'Most recent' }
          ]
        })
      );
    }

    if( $(".searchpage #stats").length != 0 ){
      search.addWidget(
        instantsearch.widgets.hits({
          container: '.searchpage #search-results',
          templates: {
            item: `
            <article class="story story--search">
              <span class="story__cat">{{ categories[0] }}</span>
              <a href="{{ url }}">
                <h4>
                  {{#helpers.highlight}}
                    { "attribute": "title", "highlightedTagName": "mark" }
                  {{/helpers.highlight}}
                </h4>
              </a>
              {{#content}}
                <p>{{#helpers.snippet}}{ "attribute": "excerpt", "highlightedTagName": "mark" }{{/helpers.snippet}}</p>
              {{/content}}

              <ul class="story__meta list--inline">
                  <li>{{ post_date }}</li>
                  <li></li>
              </ul>
            </article>
          `
          }
        })
      );
    }

    search.start();
    
  },
};
