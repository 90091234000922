import noUiSlider from 'nouislider';
import Mustache from 'mustache';
import Scroll from 'smoothscroll-polyfill';

let $dummyForm = null;
let $form = null;
let noOfPupils = 20;
let levels = [];
let filteredLevels = [];
let accType = 'school';

export default {
	init: function(){
		console.log('register:init');
		$dummyForm = document.querySelector('#website-register-form');
		$form = document.querySelector('#subscription_form');

		Scroll.polyfill();

		window.addEventListener('keydown', e => {
			if(e.keyCode === 192) {
				debugger;
			}
		});

		if(typeof $form.dataset.product_variations !== 'undefined' && $form.dataset.product_variations.length){
			levels = JSON.parse($form.dataset.product_variations);
		} else {
			console.warn('levels do not exist.');
			return false;
		}

		// temp
		if(document.getElementById('register-step-1') !== null){
			this.step1.init.bind(this)();
		}
		if(document.getElementById('register-step-2') !== null){
			this.step2.init.bind(this)();
		}

		if(document.querySelector('.rcp_registration_total') !== null){
			this.affix();
		}

		this.step3.init();


		return true;
	},
	step1: {
		init: function(){
			if(document.querySelector('.next-section') !== null){
				document.querySelectorAll('.next-section').forEach(el => {
					el.addEventListener('click', this.step1.next.bind(this));
				});
			}
		},
		next: function (ev) {
			let el = ev.currentTarget;

			if(typeof el.dataset.type === 'undefined' || !el.dataset.type.length)
				return false;

			accType = el.dataset.type;

			$dummyForm.querySelector('#register-step-2').style.display = 'none';
			$dummyForm.querySelector('#register-step-3').style.display = 'none';

			$form.elements['add-to-cart'].value = '';
			$form.elements['product_id'].value = '';
			$form.elements['variation_id'].value = '';

			if(accType === 'personal'){
				filteredLevels = levels.filter(level => {
					return (level.attributes['attribute_pa_school-size'] === 'personal');
				});

				let productsHTML = this.generateProducts();
				$dummyForm.querySelector('#register-step-3 #product-list').innerHTML = productsHTML;
				$dummyForm.querySelector('#register-step-3').style.display = 'block';
				$dummyForm.querySelector('#register-step-3 .step').innerText = '2';
				$form.querySelector('#pa_school-size').value = 'personal';
				this.step3.initProducts();
				this.scroll($dummyForm.querySelector('#register-step-3'));
			} else if(accType === 'school'){
				filteredLevels = levels.filter(level => {
					return (level.attributes['attribute_pa_school-size'] !== 'personal');
				});

				$dummyForm.querySelector('#register-step-2').style.display = 'block';
				$dummyForm.querySelector('#register-step-3 .step').innerText = '3';
				this.scroll($dummyForm.querySelector('#register-step-2'));
			}

			return true;
		}
	},
	step2: {
		init: function(){
			if($dummyForm.querySelector('#size-of-school') !== null){
				$dummyForm.querySelectorAll('#size-of-school').forEach(el => {
					this.step2.initSizeSlider(el);
				});
			}

			if($dummyForm.querySelector('#register-step-2 .next-section') !== null){
				$dummyForm.querySelector('#register-step-2 .next-section').addEventListener('click', this.step2.next.bind(this));
			}
		},
		initSizeSlider: function (el) {
			noUiSlider.create(el, {
				connect: [true, false],
				range: {
					'min': [1],
					'max': [1001]
				},
				step: 1,
				start: [20]
			});

			el.noUiSlider.on('change' , value => {
				noOfPupils = parseInt(value);
			});
			el.noUiSlider.on('update' , value => {
				let string = '',
					number = parseInt(value);

				if(number >= 1001){
					string = '1001+ pupils';
				} else if(number === 1){
					string = '1 pupil';
				} else {
					string = number + ' pupils';
				}

				el.noUiSlider.target.querySelector('.noUi-handle').dataset.size = string;
				$form.elements['school_size'].value = (number >= 1001) ? '1001+' : number;
			});
		},
		next: function(){
			//this might move
			filteredLevels = levels.filter(level => {
				return (level.attributes['attribute_pa_school-size'] !== 'personal');
			});
			filteredLevels = filteredLevels.filter(level => {
				let minMax = level.attributes['attribute_pa_school-size'].split('-');

				if(minMax.length === 1){
					minMax[1] = 9999;
				}

				return (parseInt(noOfPupils) >= parseInt(minMax[0]) && parseInt(noOfPupils) <= parseInt(minMax[1]));
			});

			$dummyForm.querySelector('#register-step-3 #product-list').innerHTML = this.generateProducts();
			$dummyForm.querySelector('#register-step-3').style.display = 'block';
			$form.elements['attribute_pa_school-size'].value = filteredLevels[0].attributes['attribute_pa_school-size'];
			this.step3.initProducts();
			this.scroll($dummyForm.querySelector('#register-step-3'));
		}
	},
	step3: {
		$modal : null,
		maxTopics : 6,
		selectedTopics: [],
		init: function(){
			this.$modal = document.querySelector('#select-topics');

			if(this.$modal === null){
				return false;
			}

			if(this.$modal.querySelector('input[type="checkbox"]') !== null) {
				this.$modal.querySelectorAll('input[type="checkbox"]').forEach(el => {
					el.addEventListener('change', this.checkMaxInputs.bind(this));
				});
			}

			if(this.$modal.querySelector('button') !== null) {
				this.$modal.querySelector('button').addEventListener('click', ev => {
					ev.preventDefault();
					$form.elements['topics'].value = this.selectedTopics.join(',');
					MicroModal.close('select-topics');

					if(accType === 'personal'){
						$form.submit();
					} else {
						$form.style.display = 'flex';
						window.scroll({
							top: (($form.getBoundingClientRect().top + document.scrollingElement.scrollTop) - 100),
							left: 0,
							behavior: 'smooth'
						});
					}

				});
			}

			return true;
		},
		initProducts: function() {
			if ($dummyForm.querySelector('#register-step-3 .product') !== null){
				$dummyForm.querySelectorAll('#register-step-3 .product button').forEach(el => {
					el.addEventListener('click', ev => {
						if(typeof ev.currentTarget.dataset.variation_id === 'undefined' || typeof ev.currentTarget.dataset.noOfTopics === 'undefined')
							return false;

						$form.querySelector('#pa_number-of-topics').value = ev.currentTarget.dataset.noOfTopics;

						this.selectedTopics = [];
						$form.elements['topics'].value = '';
						$form.elements['add-to-cart'].value = ev.currentTarget.dataset.product_id;
						$form.elements['product_id'].value = ev.currentTarget.dataset.product_id;
						$form.elements['variation_id'].value = ev.currentTarget.dataset.variation_id;
						$form.elements['number_of_topics'].value = ev.currentTarget.dataset.noOfTopics;

						if(ev.currentTarget.dataset.noOfTopics !== 'all') {
							this.maxTopics = (ev.currentTarget.dataset.noOfTopics === 'three') ? 3 : 1;
							let count = (this.maxTopics === 3) ? '3 topics' : '1 topic';
							
							document.querySelector('#select-topics-count').innerText = count;
							document.querySelectorAll('#select-topics input[type="checkbox"]').forEach(el => { el.checked = false; el.disabled = false; });
							MicroModal.show('select-topics');
						} else {
							document.querySelectorAll('#select-topics input[type="checkbox"]').forEach(el => {
								document.querySelectorAll('#select-topics input[type="checkbox"]').forEach(el => { el.checked = true; });
								this.selectedTopics.push(el.value);
							});
							$form.elements['topics'].value = this.selectedTopics.join(',');

							if(accType === 'personal'){
								$form.submit();
							} else {
								$form.style.display = 'flex';
							}

							window.scroll({
								top: (($form.getBoundingClientRect().top + document.scrollingElement.scrollTop) - 100),
								left: 0,
								behavior: 'smooth'
							});
						}

						return true;
					});
				});
			}
		},
		checkMaxInputs: function (ev) {
			let $modal = document.querySelector('#select-topics'),
				count  = $modal.querySelectorAll('input[type="checkbox"]:checked').length;

			if(ev.currentTarget.checked){
				this.selectedTopics.push(ev.currentTarget.value);
			} else{
				let index = this.selectedTopics.indexOf(ev.currentTarget.value);

				if (index !== -1)
					this.selectedTopics.splice(index, 1);
			}

			if(count === this.maxTopics){
				$modal.querySelector('button').disabled = false;
				$modal.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach(el => {
					el.disabled = true;
				});
			} else {
				$modal.querySelector('button').disabled = true;
				$modal.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach(el => {
					el.disabled = false;
				});
			}
		}
	},
	generateProducts: function () {
		let template = document.getElementById('product-template');

		let products = filteredLevels.map(product => {
			product.formattedTopics = function(){
				let text = '';

				switch (this.attributes['attribute_pa_number-of-topics']) {
					case 'one':
						text = 'Follow 1 Topic';
						break;
					case 'three' :
						text = 'Follow 3 Topics';
						break;
					case 'all':
					default:
						text = 'Follow All 6 Topics';
						break;
				}

				return text;
			};
			product.topics = function(){
				return this.attributes['attribute_pa_number-of-topics'];
			};

			return product;
		});

		return Mustache.render(template.innerText, products);
	},
	affix(){
		let $sidebar = document.querySelectorAll('.rcp_registration_total')[0];

		window.addEventListener('scroll', ev => {
			let offset = ($sidebar.getBoundingClientRect().top - document.body.scrollTop),
				$table = $sidebar.querySelector('.rcp_registration_total_details');

			if(window.getComputedStyle($sidebar.parentNode).display !== 'none' && offset < 130) {
				let formEnd = $form.offsetTop + $form.offsetHeight,
					sidebarBottom = (document.scrollingElement.scrollTop + 130) + $table.offsetHeight;

				if(formEnd <= sidebarBottom){
					$table.classList.add('affixed-bottom');
					$table.classList.remove('affixed');
				} else {
					$table.classList.remove('affixed-bottom');
					$table.classList.add('affixed');
				}

				$table.style.maxWidth = $sidebar.offsetWidth + 'px';
			} else {
				if($table !== null){
					$table.classList.remove('affixed', 'affixed-bottom');
					$table.style.maxWidth = $sidebar.offsetWidth + 'px';
				}
			}

		}, {passive: true});
	},
	scroll(el){
		let offset = el.getBoundingClientRect().top + document.scrollingElement.scrollTop;

		window.scroll({ top: (offset - 100), left: 0, behavior: 'smooth' });
	}
}
