export default {
    init() {
        
        const agreeCheckbox = document.getElementById('agree_terms_checkbox');
        const saveButton = document.getElementById('save_knowledge'); 
        const publishButton = document.getElementById('publish_knowledge');

        // Terms and Conditions Checkbox onChange event listener
        if ( agreeCheckbox ) {
            agreeCheckbox.addEventListener('change', event => {
                event.preventDefault();

                if(event.target.checked){
                    saveButton.disabled = false;
                    publishButton.disabled = false;
                } else {
                    saveButton.disabled = true;
                    publishButton.disabled = true;
                }
            });
        }

        // Register Button for login page
        const registerButton = document.getElementById('register_button');

        if (registerButton) {
            registerButton.addEventListener('click', event => {
                 MicroModal.show('select-topics');
            });
        }

    },

}
