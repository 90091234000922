import Swiper from 'swiper/dist/js/swiper.js';

export default {
	init: function(){
		if(document.querySelector('.callout-swiper') === null){
			return false;
		}

		document.querySelectorAll('.callout-swiper').forEach(el => {
			el.swiper = new Swiper(el, {
				speed: 400,
				autoplay: {
					delay: 5000
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
					bulletClass: 'pagination-link',
					bulletActiveClass: 'pagination-link--active',
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (index + 1) + '</span>';
					}
				},
			});
		});

		return true;
	}
};