import Dropzone from 'dropzone';

class DropzoneElement {
	constructor(element){
		Dropzone.autoDiscover = false;

		this.upload = typeof(element) === 'string' ? document.querySelector(element) : element;
		this.filePreview  = document.querySelector('#dropzone-file-preview').innerHTML;

		if(typeof this.upload !== 'undefined'){
			this.inputs = this.upload.previousElementSibling;

			this.initArea();
		}
	}

	initArea() {
		let options = {
			url: '/wp-admin/admin-ajax.php?action=handle_dropped_media',
			acceptedFiles: "image/*",
			addRemoveLinks: true,
			dictRemoveFile: 'Remove File',
			dictCancelUpload: 'Cancel Upload',
			previewTemplate: this.filePreview,
			init: () => {
				if(this.inputs.querySelector('input[type=hidden]') !== null){
					this.inputs.querySelectorAll('input[type=hidden]').forEach(input => {
						if(typeof input.dataset.meta !== 'undefined' && input.dataset.meta.length){
							let file = JSON.parse(input.dataset.meta);

							file.accepted = true;

							this.upload.dropzone.files.push(file);
							this.upload.dropzone.emit('addedfile', file);
							this.upload.dropzone.emit('thumbnail', file, file['url']);
							this.upload.dropzone.emit('complete', file);
							this.upload.dropzone._updateMaxFilesReachedClass();
						}
					});
				}
			},
			success: (file, response) => {
				let media = JSON.parse(response);
				console.warn(`File Upload Successful: attachment_id = ${media.attachment_id}`);

				file.previewElement.classList.add('dz-success');
				file['attachment_id'] = media.attachment_id;

				if(this.inputs !== null){
					this.populateInputsFromFiles();
				}
			},
			error: function(file) {
				this.removeFile(file);
				file.previewElement.classList.add('dz-error');

				setTimeout(() => {
					this.element.classList.remove('dz-max-files-reached');
					file.previewElement.parentNode.removeChild(file.previewElement);
				}, 2500);
			},
			removedfile: file => {
				if(typeof file.attachment_id === 'undefined'){
					this.upload.dropzone.element.classList.add('dz-started');
					return false;
				}

				let attachmentID = file.attachment_id,
					data  		 = [],
					fetchUrl	 = '/wp-admin/admin-ajax.php?';

				data['action'] = 'handle_deleted_media';

				if(attachmentID){
					data['attachment_id'] = attachmentID;
				}

				fetchUrl = fetchUrl + Object.keys(data).map(key => key + '=' + data[key]).join('&');

				file.previewElement.style.opacity = '0.4';

				fetch(fetchUrl, {
					method : "DELETE",
					credentials : 'include',
				})
				.then(response => response.json())
				.then(resp => {
					if (parseInt(resp.status) === 200) {
						if(this.inputs !== null){
							this.inputs.value = '';
						}

						this.upload.dropzone.element.classList.remove('dz-max-files-reached');
						file.previewElement.parentNode.removeChild(file.previewElement);
					} else {
						this.upload.dropzone.element.classList.remove('dz-max-files-reached');
						file.previewElement.style.opacity = '1';
					}

					this.populateInputsFromFiles();

					return true;
				})
				.catch(er => {
					console.warn(er);
				});

				return true;
			},
			maxfilesexceeded: function(file) {
				this.removeFile(file);
			}
		};

		if(typeof this.upload.dataset.maxFiles !== 'undefined' && this.upload.dataset.maxFiles.length){
			options.maxFiles = parseInt(this.upload.dataset.maxFiles);
		}

		if(typeof this.upload.dataset.fileTypes !== 'undefined' && this.upload.dataset.fileTypes.length){
			options.acceptedFiles = this.upload.dataset.maxFiles;
		}

		this.upload.dropzone = new Dropzone(this.upload, options);
	}
	populateInputsFromFiles(){
		if(typeof this.upload.dropzone === 'undefined' || typeof this.inputs === 'undefined'){
			return false;
		}

		this.inputs.innerHTML = '';

		let name = (typeof this.inputs.dataset.name !== 'undefined') ? this.inputs.dataset.name : 'attachment-id';

		this.upload.dropzone.files.forEach(file => {
			let input = document.createElement('input');
			input.type = 'hidden';
			input.name = name;
			input.value = file.attachment_id;

			this.inputs.appendChild(input);
		});

		return true;
	}
}

export { DropzoneElement as default };
