export default {
    init() {
		if(document.querySelector('.archive-filter') !== null){
			document.querySelectorAll('.archive-filter').forEach(el => {
				el.addEventListener('change', ev => {
					let form = ev.currentTarget;

					setTimeout(() => {
						console.log(ev.currentTarget);
						form.submit();
					}, 1000);
				})
			})
		}



		// const topicFilter = document.getElementById('topic_filter_select');
        //
        // if (topicFilter) {
        //     topicFilter.addEventListener('change', event => {
        //         let filter = event.currentTarget.value;
        //         console.log(`Dropdown Changed: ${filter}`);
        //         // update url, and load
        //         location.href = this.URL_add_parameter(location.href, 'topic', filter);
        //     });
        // }
        //
        // const sortPosts = document.getElementById('sort_posts_select');
        //
        // if (sortPosts) {
        //     sortPosts.addEventListener('change', event => {
        //         let filter = event.currentTarget.value;
        //         console.log(`Dropdown Changed: ${filter}`);
        //         location.href = this.URL_add_parameter(location.href, 'order-by', filter);
        //     });
        // }

    },

    /**
     * javascript - append to url and refresh page - Stack Overflow
     * 
     * Replaces or appends to url params
     * 
     * URL: https://stackoverflow.com/questions/5997450/append-to-url-and-refresh-page
     * Date: 23/08/2019
     * 
     * location.href = URL_add_parameter(location.href, 'param', 'value');
     * @param url
     * @param param
     * @param value
     */

    URL_add_parameter(url, param, value){
        var hash       = {};
        var parser     = document.createElement('a');
    
        parser.href    = url;
    
        var parameters = parser.search.split(/\?|&/);
    
        for(var i=0; i < parameters.length; i++) {
            if(!parameters[i])
                continue;
    
            var ary      = parameters[i].split('=');
            hash[ary[0]] = ary[1];
        }
    
        hash[param] = value;
    
        var list = [];
        Object.keys(hash).forEach(function (key) {
            list.push(key + '=' + hash[key]);
        });
    
        parser.search = '?' + list.join('&');
        return parser.href;
    }

}